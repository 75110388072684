<template>
<div class="registration-lookup">
   <header class="page-header">
       <h2>Star Leasing Public FHWA PMs and Registration</h2>
   </header>
   <div class="content-wrapper">
       <p>If no digital copy is available, please call <a href="tel:6142789999">(614) 278-9999</a> for assistance.</p>
       <div class="combo-search">
            <select v-model="column">
                <option value="trailer-number">Trailer #</option>
                <option value="plate-number">Plate #</option>
            </select>
           <label class="search">
               <input type="text" placeholder="Search" v-model="search" />
           </label>
       </div>
       <transition name="autocordion">
           <div class="autocordion" v-if="Object.keys(filteredData).length">
               <div>
                    <simple-table :data="filteredData" :columns="equipmentColumns" :model="EquipmentModel">
                        <template v-slot:rowcontrols>
                            <a class="button" href="">Documents</a>
                        </template>
                    </simple-table>
                </div>
           </div>
       </transition>
       <transition name="autocordion">
           <div v-if="!Object.keys(filteredData).length">
               <div>
                   <p v-if="search.length">There are no records matching your search</p>
                   <p v-else>Begin searching to show records</p>
               </div>
           </div>
       </transition>
   </div>
</div>
</template>

<script>
import SimpleTable from '../components/tables/SimpleTable.vue';
import EquipmentModel from '../models/EquipmentModel';

import axios from 'axios';

const equipmentColumns = {
    'trailer-number' : {
        title : "Trailer #",
    },
    'year' : {
        title: "Year",
    },
    'make' : {
        title: "Make",
    },
    'plate-number' : {
        title: "Plate #",
    },
    'description' : {
        title: "Description",
    }

};


export default {
    components: {
        SimpleTable
    },
    data:
        function(){
        return {
            search: '',
            column: 'trailer-number',
            equipmentColumns,
            EquipmentModel: new EquipmentModel
        }
    },
    computed: {
        filteredData: function(){
            if (this.search.length < 1){
                return {};
            }
            
            let filteredDataBuilder = Object.entries(this.$root.$data[EquipmentModel.name]);

            const lowerCaseFilter = this.search.toLowerCase();
            if (!Object.values(filteredDataBuilder)[0][1].__populated){
                return {};
            }
            filteredDataBuilder = filteredDataBuilder.filter(item => {
                    return item[1][this.column] && item[1][this.column].toLowerCase() === lowerCaseFilter;
            });

            filteredDataBuilder = Object.fromEntries(filteredDataBuilder);

            return filteredDataBuilder;
        }
    },
    created: function(){
        axios.get('https://mockapi.starleasing.dev/equipment').then((response) => {
            this.$root.$data[EquipmentModel.name].populate(response.data);
        }, (e) => {
            console.log(e);
            //!! need handler for errors
        });
    }
}
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";
.registration-lookup{

    .row-controls{
        width: 150px;
    }
    
    .content-wrapper{
        min-height: 400px;
    }

    .combo-search{
        display: flex;
        margin-bottom: $space-wide;

        select{
            border-radius: $input-radius 0 0 $input-radius;
            flex: 1 1 auto;
        }
        .search{
            width: 80%;
            flex: 0 0 auto;
        }
        input {
            border-radius: 0 $input-radius $input-radius 0;
            border-left-width: 0;
        }
    }
    table{
        width: 100%;
    }
}
</style>